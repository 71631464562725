<template>
  <div v-if="type != 'Grouped' && type != 'NoValue'">
    <primaryTextfield
      v-if="this.inputType == 'static' && !isEnumerated"
      :value="localValue"
      :fieldAttrInput="fieldAttributes.fieldAttrInput"
      :label="fieldAttributes.name"
      :templateContent="result"
      v-on="$listeners"
    ></primaryTextfield>

    <associatedOption
      v-else-if="this.inputType == 'static' && isEnumerated"
      :fieldAttributes="{ name: fieldAttributes.name, options: options }"
      type="combobox"
      :fieldAttrInput="fieldAttributes.fieldAttrInput"
      :value="localValue"
      v-on="$listeners"
      @input="emitChanged"
      :templateContent="result"
    ></associatedOption>
    <associatedOption
      v-else-if="this.inputType == 'dynamic'"
      :fieldAttributes="{
        name: fieldAttributes.name,
        options: fieldAttributes.radiusDynamicPars,
      }"
      type="combobox"
      :fieldAttrInput="fieldAttributes.fieldAttrInput"
      :value="localValue"
      v-on="$listeners"
      @input="emitChanged"
      :templateContent="result"
    ></associatedOption>
  </div>
</template>
<script>
// import { getDynamicAttributes } from "@/js/testHelpers.js";configureand
import { arrayFindInArray } from "@/js/helper.js";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
export default {
  components: { primaryTextfield, associatedOption },
  props: ["result", "fieldAttributes", "checkReadOnly", "name", "value"],
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    // processedValue() {},
    inputType() {
      return this.result.valueLine.type;
    },
    attribute() {
      return this.result.valueLine.attribute;
    },
    application() {
      return this.fieldAttributes.application;
    },

    type() {
      let valueArray = arrayFindInArray(
        this.result.valueLine.attribute,
        this.result.fields.attribute.associatedOption
      );
      //     tag = valueArray[1];
      let type = valueArray[2];
      //     tag = tag + "*";
      return type;
    },
    isEnumerated() {
      let isEnumerated = false;
      let attribute = this.attribute;
      let application = this.application;
      // var applicationValue = fieldsObject.data("enumerated")[application];
      let type = this.type;
      var applicationValue = this.fieldAttributes.applicationValue;
      if (
        type == "Enumerated" ||
        (typeof applicationValue != "undefined" &&
          typeof applicationValue != "undefined" &&
          typeof applicationValue[attribute] != "undefined" &&
          applicationValue[attribute].length > 0) ||
        ((application == "RADIUS_ACCOUNTING" ||
          application == "RADIUS_ACCOUNTING") &&
          type == "integer" &&
          this.options != null &&
          this.options.length != 0)
      ) {
        isEnumerated = true;
      }
      return isEnumerated;
    },
  },
  asyncComputed: {
    async options() {
      let options = [];
      let attribute = this.attribute;
      let application = this.application;
      // var applicationValue = fieldsObject.data("enumerated")[application];
      let type = this.type;
      var applicationValue = this.fieldAttributes.applicationValue;
      // console.log(applicationValue);
      if (type == "Enumerated") {
        let value = {};
        value["c_release"] = 0;
        value["attribute"] = attribute;
        value["application"] = application;
        let caller = this;
        options = await new Promise((resolve) => {
          caller.frameworkAxiosRequest({
            method: 'post',
            url: 'serve.php?f=testing&f2=configureAndRunTests',
            data: {
              function: 'getEnumerationAttributes',
              value: value,
            },
          })
              .then(function (response) {
              resolve(response.data.result.json);
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });
        });
      } else if (
        (typeof applicationValue != "undefined" &&
          typeof applicationValue != "undefined" &&
          typeof applicationValue[attribute] != "undefined" &&
          applicationValue[attribute].length > 0) ||
        ((application == "RADIUS_ACCOUNTING" ||
          application == "RADIUS_ACCOUNTING") &&
          type == "integer")
      ) {
        options = applicationValue[attribute];
        // console.log("enumerated");
        // var callBackArguments = {};
        // callBackArguments["value"] = value;
        // callBackArguments["release"] = release;
        // callBackArguments["attribute"] = attribute;
        // callBackArguments["application"] = application;
        // //callBackArguments["fieldsObject"] = fieldsObject;
        // // getEnumeratedAttributes($row,changeToSelect,callBackArguments);
        // getEnumeratedAttributes(
        //   //  $row,
        //   //  changeToSelectAndRefreshAccordion,
        //   callBackArguments
        // );
      } else {
        // toggleSupress($row);
        // checkNoValue($row);
        //   let $mandatory = $row.find(".mandatory");
        //  toggleMandatory($mandatory);
      }
      return options;
    },
  },
  methods: {
    emitChanged(value) {
      this.$emit("change", value);
    },
    renderInputValue() {
      // $row,
      // value,
      // inputType,
      // readonly,
      // type,
      // required,
      // attrValue,
      // $mainContainer,
      // randomIdTree,
      // attrTree,
      // sourceTree
      // var fieldsObject = $row.closest(".fields");
      // var originalValue = value;

      // if (typeof required == "undefined") {
      //   required = "required";
      // }

      // if (typeof attrValue != "undefined" && attrValue.fix == 2) {
      //   required = "";
      // }
      // if (typeof value == "undefined" || value == "") {
      //   // value given check if eval is in string.

      //   originalValue = $row
      //     .find(".fixSelect")
      //     .children(".attrValue")
      //     .attr("originalValue");
      //   if (typeof originalValue != "undefined") {
      //     value = originalValue;
      //   } else {
      //     value = "";
      //   }
      // }
      //let inputType= getDynamicAttributes(value);
      let value = this.localValue;
      let inputType = this.inputType;
      if (inputType == "static") {
        //console.log(value);
        if (typeof value == "string" && value.indexOf("EVAL:") > -1) {
          value = "";
        }
        let type = this.type;

        // // clear grouped diameter
        // var $attributeRow = $row.closest(".attributeRow");
        // //var $groupedAttrRow=$row.find('tr');
        // var $groupedAttrRow = $row.find(".groupedAttrRow");
        // //if ($row.find('tr').hasClass('groupedAttrRow')){
        // if ($groupedAttrRow.length > 0) {
        //   //$attributeRow.html($row.closest('tr').html());
        //   var attrValue = $groupedAttrRow.find(".requestAtrrClass").val();
        //   var $clonedGroupedAttrRow = $groupedAttrRow.clone(true, true);
        //   $attributeRow.html($clonedGroupedAttrRow.html());
        //   $attributeRow.find(".requestAtrrClass").val(attrValue);
        //   $attributeRow.find(".dynamicValue").remove();
        //   $attributeRow.find(".removeAttributesGroupLink").remove();
        //   $attributeRow
        //     .find(".staticValue")
        //     .replaceWith(getValueTypeFields(value, type));
        //   $attributeRow.find("td.value input").show();
        // }

        // var application = $row
        //   .closest(".application")
        //   .attr("data-application-type");

        // if (typeof type == "undefined" || type == "") {
        //   // ////console.log("here",$row.children('.treeselector'));
        //   type = $row.children(".treeselector").attr("data-type");
        // }
        // type = $.trim(type);
        // var $valueArea = $attributeRow.find("td.value .attrValue");

        // if ($valueArea.length == 0) {
        //   if (attrValue.mandatory == 1) {
        //     required = "";
        //   }
        //   let changeListner = "";
        //   if (application == "RADIUS_ACCOUNTING") {
        //     changeListner = "radiusAcountingValueListener";
        //   }
        //   $row.find(".fixSelect")[0].innerHTML =
        //     "<input type='text' class='" +
        //     required +
        //     " selectTypeDependent attrValue tPlus " +
        //     changeListner +
        //     "' " +
        //     readonly +
        //     " ></input >";
        // } else if ($valueArea[0].tagName.toLowerCase() == "select") {
        //   var attributes = $valueArea.prop("attributes");
        //   $valueArea.replaceWith("<input type='text'></input>");
        //   // var $input =$row.closest('.attributeRow').find('.value input');
        //   var $input = $attributeRow.find(".value input");
        //   ////////////console.log($selector);
        //   $.each(attributes, function() {
        //     $input.attr(this.name, this.localValue);
        //   });
        // }
        // let $fixSelectInput = $row.find(".fixSelect").find("input");
        // var id = $fixSelectInput.uniqueId().attr("id");
        // $fixSelectInput.attr("name", id);
        // $fixSelectInput.val(value);
        // $fixSelectInput.attr("originalValue", originalValue);

        // $row = $attributeRow;
        // if (type === "Grouped") {
        //   var callBackArguments = {};
        //   callBackArguments["title"] = $(
        //     "option:selected",
        //     $attributeRow
        //   ).text();
        //   var release = $attributeRow
        //     .closest(".application")
        //     .find(".releasesselect")
        //     .val();

        //   var attribute = $attributeRow
        //     .closest(".treenode")
        //     .find(".treeselector")
        //     .attr("attrid");

        //   callBackArguments["release"] = release;
        //   callBackArguments["attribute"] = attribute;
        //   callBackArguments["fieldsObject"] = fieldsObject;
        //   //var application=$row.closest('.application').attr("data-application-type");
        //   callBackArguments["content"] = value;
        //   callBackArguments["application"] = application;
        //   //callBackArguments['release']=release;
        //   callBackArguments["attribute"] = attribute;
        //   callBackArguments["attributeRow"] = $attributeRow;
        //   // this is needed in the get release function
        //   callBackArguments["requesterObject"] = $attributeRow;
        //   callBackArguments["mainContainer"] = $mainContainer;
        //   callBackArguments["randomIdTree"] = randomIdTree;
        //   callBackArguments["attrTree"] = attrTree;
        //   callBackArguments["sourceTree"] = sourceTree;

        //   // console.log("attributesTbody", $attributesTbody);

        //   if (application != "EAP") {
        //     if (attribute != "") {
        //       getGroupedAttributes(changeToGrouped, callBackArguments);
        //     } else {
        //       changeToGrouped([], callBackArguments);
        //       //$row.closest('.ui-accordion').accordion( "refresh" );
        //     }
        //   } else {
        //     changeToEAPGrouped([], callBackArguments);
        //   }
        // } else

        {
          //$row.find(".addGroupTd").html("");
          // var release=$row.closest('.application').find(".releasesselect").val();
          //if (typeof release!="undefined"){
          let release = 0;
          // var attribute = $row
          //   .closest(".attributeRow")
          //   .find(".treeselector")
          //   .attr("attrid");
          let attribute = this.attribute;
          let application = this.application;
          // var applicationValue = fieldsObject.data("enumerated")[application];

          var applicationValue = this.fieldAttributes.applicationValue;
          console.log(applicationValue);
          if (
            type == "Enumerated" ||
            (typeof applicationValue != "undefined" &&
              typeof applicationValue != "undefined" &&
              typeof applicationValue[attribute] != "undefined" &&
              applicationValue[attribute].length > 0) ||
            ((application == "RADIUS_ACCOUNTING" ||
              application == "RADIUS_ACCOUNTING") &&
              type == "integer")
          ) {
            var callBackArguments = {};
            callBackArguments["value"] = value;
            callBackArguments["release"] = release;
            callBackArguments["attribute"] = attribute;
            callBackArguments["application"] = application;
            //callBackArguments["fieldsObject"] = fieldsObject;
            // getEnumeratedAttributes($row,changeToSelect,callBackArguments);
            getEnumeratedAttributes(
              //  $row,
              //  changeToSelectAndRefreshAccordion,
              callBackArguments
            );
          } else {
            // toggleSupress($row);
            // checkNoValue($row);
            //   let $mandatory = $row.find(".mandatory");
            //  toggleMandatory($mandatory);
          }
          // }else {

          // }
        }
      } else {
        // dynamic
        // renderDynamicValues(
        //   // $row,
        //   "IPCAN",
        //   value
        // );
      }

      //  refreshTestCaseAccordion(fieldsObject);
    },
  },
};

// function getGroupedAttributes(groupedCallBackFunction, callBackArguments) {
//   var application = callBackArguments["application"];
//   var fieldsObject;
//   fieldsObject = callBackArguments["fieldsObject"];
//   var release;
//   release = callBackArguments["release"];
//   var attribute;
//   attribute = callBackArguments["attribute"];
//   if (typeof release == "undefined") {
//     release = 0;
//     callBackArguments["release"] = 0;
//   }
//   release = parseInt(release) | 0;
//   if (
//     typeof callBackArguments["release"] != "undefined" &&
//     typeof callBackArguments["attribute"] != "undefined"
//   ) {
//     if (application == "EAP") {
//       groupedCallBackFunction([], callBackArguments);
//     } else if (
//       typeof fieldsObject.data("attributes")[application] != "undefined" &&
//       typeof fieldsObject.data("attributes")[application][release] !=
//         "undefined" &&
//       typeof fieldsObject.data("attributes")[application][release][attribute] !=
//         "undefined"
//     ) {
//       groupedCallBackFunction(
//         fieldsObject.data("attributes")[application][release][attribute],
//         callBackArguments
//       );
//     } else {
//       getReleaseAttributes(
//         release,
//         attribute,
//         groupedCallBackFunction,
//         callBackArguments
//       );
//     }
//   }
// }

// function renderDynamicValues($row, type, value) {
//   // dynamic
//   var testLeveltype = $row.closest(".testLevel").attr("data-testLeveltype");
//   let $attrValue = $row.find(".fixSelect").find(".attrValue");
//   var id = $attrValue.attr("id");
//   //console.log($row,$row.closest(".testLevel"),testLeveltype);
//   if (testLeveltype != "HTTP") {
//     testLeveltype = "IPCAN";
//   }
//   //$row.find('.value').html(makeSelector( window.dynamicPars[testLeveltype], "","attrValue required")+"<label  class='error'></label>");
//   $row.find(".value")[0].innerHTML = makeSelector(
//     window.dynamicPars[testLeveltype],
//     value,
//     "attrValue required"
//   );
//   /////get att value again after rendering
//   $attrValue = $row.find(".fixSelect").find(".attrValue");
//   if (typeof id == "undefined") {
//     id = $attrValue.uniqueId().attr("id");
//   } else {
//     $attrValue.attr("id", id);
//   }
//   $attrValue.attr("name", id);
//   //$row.find('.fixSelect').find('.attrValue').val(value);
//   $attrValue.attr("originalValue", value);
//   $row
//     .find(".fixSelect")
//     .find("label")
//     .attr("for", id);
// }

// function getReleaseAttributes(
//   release,
//   attribute,
//   groupedCallBackFunction,
//   callBackArguments
// ) {
//   let $requesterObject = callBackArguments["requesterObject"];
//   let $fieldsObject = callBackArguments["fieldsObject"];
//   var application = callBackArguments["application"];
//   var requestType = $requesterObject
//     .closest(".treenode")
//     .attr("data-node-type");
//   if (typeof requestType != "undefined" && attribute != "") {
//     var value = {};
//     value["c_release"] = release;
//     value["treeSelectorType"] = attribute;
//     value["application"] = application;
//     var url = "serve.php?f=configuration&f2=protocolsCommon";
//     let attrSource = $fieldsObject.data("attrSource");
//     let attributesArray = getAttributesArray(
//       [],
//       window.dictionarisArray[application]
//     );

//     groupedCallBackFunction(attributesArray, callBackArguments);

//     /* $.ajax({
//                     type: "POST",
//                     url: url,
//                     data: { 'function': requestType , "value":value ,"attrSource":attrSource, "caller":"getReleaseAttributes"},
//                     dataType: "json",
//                     success:  getReleaseAttributesCallBack(groupedCallBackFunction,callBackArguments),
//                         });
//                 */
//   } else {
//   }
// }

// function getAttributesArray(weightedArray, dictionary) {
//   var packetAttributes = [];
//   if (typeof weightedArray != "undefined" && weightedArray != null) {
//     $.each(weightedArray, function(index, weight) {
//       var value = arrayFindInArray(index, dictionary);
//       minMax = getMinMax(weight);
//       packetAttributes.push([index, value[1], value[2], minMax]);
//     });
//   }
//   return packetAttributes;
// }

function getEnumeratedAttributes(
  $row,
  enumerationCallBackFunction,
  callBackArguments
) {
  var application = callBackArguments["application"];
  let fieldsObject = callBackArguments["fieldsObject"];
  var release;
  if (typeof callBackArguments["release"] != "undefined") {
    release = callBackArguments["release"];
  }

  var attribute;
  if (typeof callBackArguments["attribute"] != "undefined") {
    attribute = callBackArguments["attribute"];
  }

  if (
    typeof fieldsObject.data("enumerated")[application] != "undefined" &&
    typeof fieldsObject.data("enumerated")[application][release] !=
      "undefined" &&
    typeof fieldsObject.data("enumerated")[application][release][attribute] !=
      "undefined"
  ) {
    enumerationCallBackFunction(
      $row,
      fieldsObject.data("enumerated")[application][release][attribute],
      callBackArguments
    );
  } else if (
    application != "RADIUS_ACCOUNTING" &&
    application != "RADIUS_ACCOUNTING"
  ) {
    // getting enumeration from backend is only for diameter. radius is allready sent
    getReleaseEnumeration(
      $row,
      release,
      attribute,
      enumerationCallBackFunction,
      callBackArguments
    );
  }
}

function getReleaseEnumeration(
  $row,
  release,
  attribute,
  enumerationCallBackFunction,
  callBackArguments
) {
  var callbackFunction = function (callBackArguments, data) {
    var application = callBackArguments["application"];
    let fieldsObject = callBackArguments["fieldsObject"];
    let release = callBackArguments["release"];
    let attribute = callBackArguments["attribute"];

    //if (typeof fieldsObject != "undefined") {
    //  return function(data) {
    var enumeration = data;
    if (typeof fieldsObject.data("enumerated") == "undefined") {
      fieldsObject.data("enumerated", {});
    }
    if (typeof fieldsObject.data("enumerated")[application] == "undefined") {
      fieldsObject.data("enumerated")[application] = {};
    }
    if (
      typeof fieldsObject.data("enumerated")[application][release] ==
      "undefined"
    ) {
      fieldsObject.data("enumerated")[application][release] = {};
    }
    fieldsObject.data("enumerated")[application][release][
      attribute
    ] = enumeration;
    enumerationCallBackFunction(enumeration, callBackArguments);
    //  };
    // }
  };
  var application = callBackArguments["application"];
  var value = {};
  value["c_release"] = release;
  value["attribute"] = attribute;
  value["application"] = application;
  var fieldsObject = $row.closest(".fields");
  if (typeof callBackArguments["fieldsObject"] != "undefined") {
    fieldsObject = callBackArguments["fieldsObject"];
  }
  var url = fieldsObject.attr("action");

  // $.ajax({
  //   type: "POST",
  //   url: url,
  //   data: { function: "getEnumerationAttributes", value: value },
  //   dataType: "json",
  //   success: callbackFunction($row, callBackArguments)
  // });

  this.frameworkAxiosRequest({
    method: 'post',
    url: url,
    data: {
      function: 'getEnumerationAttributes',
      value: value,
    },
  })
      .then(function (response) {
      callbackFunction(callBackArguments, response.data.result.json);
    })
    .catch(function (response) {
      //handle error
      console.log(response);
    });
}
</script>